<template>
  <div
    class="text-divider"
    :style="{textAlign: align}"
  >
    <span v-if="text.length > 0"><b>{{ text }}</b></span></div>
</template>
<script>
export default {
  name: 'I2Divider',
  props: {
    text: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'center',
    },
  },
}
</script>

<style scoped>
.text-divider{margin: 5px 0; line-height: 0; text-align: center; padding-bottom: 8px}
.text-divider span{background-color: white; padding-left: 1em; padding-right:1em;padding-bottom: 5px;font-size: 1.3em}
.text-divider:before{ content: " "; display: block; border-top: 1px solid #e3e3e3; border-bottom: 1px solid #f7f7f7;}
</style>
